import React from 'react';
import ReactDOM from 'react-dom';
import * as MaterialUI from '@mui/material';
import * as MaterialUILab from '@mui/lab';
import * as xDatePickers from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import * as XDataGrid from '@mui/x-data-grid';
import { IMaskInput } from 'react-imask';
import SignatureCanvas from 'react-signature-canvas';
import { readAndCompressImage } from 'browser-image-resizer';
import FullCalendar from '@fullcalendar/react';
import FullCalendarDayGridPlugin from '@fullcalendar/daygrid';
import FullCalendarTimeGridPlugin from '@fullcalendar/timegrid';
import FullCalendarInteractionPlugin from '@fullcalendar/interaction';


window.React = React;
window.ReactDOM = ReactDOM;
window.MaterialUI = MaterialUI;
window.MaterialUILab = MaterialUILab;
window.xDatePickers = xDatePickers;
window.AdapterDayjs = AdapterDayjs;
window.dayjs = dayjs;
window.XDataGrid = XDataGrid;
window.IMaskInput = IMaskInput;
window.SignatureCanvas = SignatureCanvas;
window.readAndCompressImage = readAndCompressImage;
window.FullCalendar = FullCalendar;
window.FullCalendarDayGridPlugin = FullCalendarDayGridPlugin;
window.FullCalendarTimeGridPlugin = FullCalendarTimeGridPlugin;
window.FullCalendarInteractionPlugin = FullCalendarInteractionPlugin;
